<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">
    <aside>
      <div>提示：页面数据的日统计时间已延后{{statisticData.afterHour}}小时</div>
    </aside>
    <el-card class="SearchBox">
      <el-row>
        <el-form :model="searchFormData" :inline="true">
          <el-form-item label="所属门店" prop="shopId">
            <el-select v-model="searchFormData.shopId" placeholder="请选择" @change="handleChanged">
              <el-option v-for="item in shopDrops" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-row>
    </el-card>
    <el-card>
      <div id="myChart" :style="{width: '100%','min-height':'500px', height: '100%'}"></div>
    </el-card>
  </div>
</template>

<script>
import { getStoreCheckDateStatistic } from '@/service/statistics.js';
import { dropShop } from '@/service/shop.js';

export default {
  data() {
    return {
      loading: false,
      shopDrops: [
        {
          label: '--全部--',
          value: 0,
        }
      ],
      statisticData: {
        title: '',
        maxCount: 0,
        dateList: [],
        items: [],
        productList: []
      },
      searchFormData: {
        shopId: 0,
        isDate: false,
      },
    }
  },
  mounted() {
    // this.drawLine();
  },
  created() {
    this.DataBinding();
  },
  methods: {
    //数据加载
    DataBinding() {
      this.loading = true;
      this.DropStores();
      getStoreCheckDateStatistic(this.searchFormData).then(res => {
        console.log(res)
        this.statisticData = res.data;
        this.drawLine();
        this.loading = false;
      }).catch(err => {
        this.loading = false;
      });
    },
    //获取门店下拉框数据
    DropStores() {
      dropShop().then(res => {
        this.shopDrops = [{
          label: '--全部--',
          value: 0,
        }];
        if (res.code == 0) {
          res.data.forEach(element => {
            this.shopDrops.push(element);
          });
        }
      });
    },
    //选中门店
    handleChanged(shopId) {
      this.DataBinding();
    },
    //绘制图表
    drawLine() {
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      myChart.setOption({
        title: {
          text: this.statisticData.title,
          subtext: '',
          x: 'left'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: this.statisticData.productList,
        },
        xAxis: [
          {
            type: 'category',
            data: this.statisticData.dateList,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            max: this.statisticData.maxCount,
            interval: this.statisticData.maxCount / 10,
            axisLabel: {
              formatter: '{value} '
            }
          }
        ],
        dataZoom: {
          show: true,
          realtime: true,
          start: 0,
          zoomLock: false,
        },
        series: this.statisticData.items,
      });
    }
  }
};
</script>

<style scoped>
.SearchBox {
  margin-bottom: 30px;
}
</style>